import React from 'react'
import { graphql, Link } from 'gatsby'
import { Heading5 } from 'components/design'

export default function ProjectAttributes({
	className,
	externalDescription,
	hideLink,
	project,
	contrastWhite,
}) {
	if (!project || !project.data) {
		return null
	}
	const { project_name, architect, location, one_liner_description } = project.data

	const parts = []

	if (externalDescription) {
		parts.push(externalDescription)
	}
	if (one_liner_description) {
		parts.push(one_liner_description)
	}
	if (location) {
		parts.push(location)
	}
	if (architect) {
		parts.push('Architect: ' + architect)
	}

	const textLine = ' ' + parts.join(' | ')
	const heading = <Heading5 className="inline">{project_name + '.'}</Heading5>

	return (
		<div className={`${className || 'mt-4'}`}>
			{hideLink ? (
				heading
			) : (
				<Link className="accent-hover" to={`/projects/${project.uid}/`}>
					{heading}
				</Link>
			)}
			<span className={contrastWhite ? 'text-white' : 'text-pale'}>{textLine}</span>
		</div>
	)
}

export const query = graphql`
	fragment ProjectAttributesFragment on PrismicLinkType {
		document {
			... on PrismicProject {
				id
				uid
				data {
					project_name
					architect
					location
				}
			}
		}
	}
`
