import React from 'react'
import { graphql } from 'gatsby'
import ProjectAttributes from 'components/project-attributes'
import { Rimg } from 'components/images'

const Img = ({ gallery_image, project, category }) => (
	<div key={gallery_image.url} className={`mb-16`}>
		<Rimg image={gallery_image} position="column" />
		{project?.document ? (
			<ProjectAttributes project={project.document} />
		) : (
			<strong>Project is not set too</strong>
		)}

		{category ? <strong>{category}</strong> : null}
	</div>
)

export default function GalleryInvisible({ data }) {
	const { list } = data.prismicProjectsGallery.data
	const woCategory = list.filter(({ category }) => !category)
	const woProject = list.filter(({ project }) => !project?.document?.id)

	return (
		<main className="px-20">
			<h3 className="text-3xl font-bold">Invisible Gallery Items</h3>

			<strong className="mt-10 block">Category unset:</strong>
			{woCategory.map(Img)}

			<strong className="mt-20 block">Project unset:</strong>
			{woProject.map(Img)}
		</main>
	)
}

// keep in sync with ~/src/node-pages/project-list
export const query = graphql`
	query galleryInvisibleQuery {
		prismicProjectsGallery {
			data {
				list {
					category
					description
					gallery_image {
						alt
						dimensions {
							height
							width
						}
						url
					}
					project {
						...ProjectAttributesFragment
					}
				}
			}
		}
	}
`
